import React from 'react'
import HubspotForm from 'react-hubspot-form'
import TestimonialsEn from '../components/Testimonials/TestimonialsEn';
import Layout from '../components/layout'

const Newpulsepage = ({ location }) => {
  return (
    // <section className = "w-full p-0 pb-0 lg:p-5 flex flex-col place-items-center bg-indigo-100 relative overflow-x-hidden">
    //     <div className = "sectionContainer flex flex-col lg:flex-row">
    //         <div className = "flex flex-col justify-center items-center lg:items-left w-full lg:w-3/4">
    //             <h2 className = "bannerTitle my-2 xl:my-4 text-purple-100"><span className = "relative">Celebrate<img className = "absolute bottom-0 right-12" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /></span> Greatwork, <br/>Celebrate Teamwork.</h2>
    //             <p className = "text-lg xl:text-xl"></p>
    //         </div>
    //         <div className = "flex flex-col justify-center items-center lg:items-left w-full lg:w-1/4">
                
    //         </div>
    //     </div>
    // </section>
    <Layout location = {location}>
    <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-0 lg:py-12 overflow-y-hidden z-0">
        <div class="newSectionContainer grid grid-cols-1 lg:grid-cols-2 gap-5 justify-center items-center z-10">
            <div class="w-full flex flex-col text-center lg:text-left justify-center lg:items-start items-center">
                <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full border border-gray-darklight bg-indigo-50">
                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/pulse-ico.png" alt="Vantage Pulse" height="15" width="15" />
                    <h2 class="text-purple-100 text-sm lato"><span class="font-bold">&nbsp;&nbsp;VANTAGE </span>PULSE</h2>
                </div>

                <h1 class="text-purple-100 bannerSectionTitle-2">Get real-time employee <span className = "relative">feedback<img className = "absolute -bottom-1 right-0 xl:right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span></h1>

                {/* <h2 class="text-gray-100 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl mb-5 font-bold text-center">of your organization.</h2> */}
                <p class="my-8 text-lg xl:text-xl text-purple-100">Get exclusive offers and discounts along with crazy cashbacks from over 1000+ brands.</p>
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-3 justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-full xl:w-8/12 2xl:w-1/2 cursor-pointer"><button class="vc-new-orange-btn lato">Request Demo</button><button class="vc-ghost-btn-white lato">Watch Video</button></div>
            </div>
            {/* <img loading="lazy" class="hidden lg:block absolute top-32 xl:top-24 2xl:top-16 right-32 xl:right-56 2xl:right-64" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-31.png"/> */}
            <div className="w-full opacity-0 lg:hidden justify-center items-center mt-6"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Pulse-hero-image.png" alt="Vantage Rewards" width="751" height="443"/></div>
            <div className="w-full flex lg:hidden absolute right-0 md:-right-3 bottom-0 justify-center md:justify-end items-center mt-6"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Pulse-hero-image.png" alt="Vantage Rewards" width="651" height="343"/></div>
        </div>
        <div class="z-10 lg:flex justify-center lg:justify-end items-center my-9 lg:my-0 absolute lg:-right-40 xl:-right-28 2xl:-right-10 lg:-bottom-16 xl:-bottom-10 hidden transform lg:scale-75 xl:scale-90 2xl:scale-100"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Pulse-hero-image.png" alt="Vantage Rewards" width="751" height="443"/></div>
        {/* <img class="hidden lg:block absolute top-20 right-0 z-0" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/pulse-stroke-1.png"/> */}
    </section>
    <section className = "w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-12 overflow-y-hidden">
        <div className = "newSectionContainer lg:flex">
            <h2 className = "rewardsSectionTitle text-gray-250 lg:border-r border-r-gray-100 w-full lg:w-5/12 2xl:w-4/12 pr-0 lg:pr-3 xl:pr-1">Great Reviews that make us great</h2>
            <div className = "grid grid-cols-3 w-full lg:w-7/12 2xl:w-8/12 my-5 lg:my-0">
                <div className = "flex place-content-center p-2 lg:p-5"><img loading ="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-11.png" /></div>
                <div className = "flex place-content-center p-2 lg:p-5"><img loading ="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-19.png" /></div>
                <div className = "flex place-content-center p-2 lg:p-5"><img loading ="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-23.png" /></div>
            </div>
        </div>
    </section>
    <section className = "w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-8 overflow-y-hidden" style = {{background: 'linear-gradient(142deg, #FCFAFF 0%, #FBF0FF 100%)'}}>
        <div className = "lg:flex rewardsSectionContainer">
            <div class="card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-2/3">
                <h2 class="bannerSectionTitle-2 text-gray-250 font-bold pt-5 mb-8 md:px-12 lg:px-0 2xl:w-8/12">Recognize and engage employees with <span className = "relative">ease.<img loading = "lazy" className = "absolute -bottom-1 right-0 xl:right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span></h2>
                <ul class="list-inside orangebullets text-gray-250 lato text-lg xl:text-xl md:text-md 2xl:text-lg md:px-12 lg:px-0 w-full lg:w-8/12">
                    <li className = "py-1">Are you experiencing a lack of engagement and motivation among your employees?</li>
                    <li className = "py-1">Is toxic culture something you are trying to avoid?</li>
                    <li className = "py-1">No way for your workforce to be rewarded and recognized socially in real-time?</li>
                    <li className = "py-1">Is there no meaningful social interaction among employee peer groups?</li>
                    <li className = "py-1">Do you already have a rewards and recognition program, but it is not centralized globally?</li>
                    <li className = "py-1">Do you find it challenging to reward your dispersed workforce that is diverse and multi-lingual?</li>
                </ul>
                {/* <div className = "flex justify-center lg:justify-start w-full mt-10 lg:mt-10"><div className = "w-full md:w-1/2 lg:w-4/6 xl:w-1/2 2xl:w-1/3 flex justify-center items-center"><button className = "vc-ghost-btn-black lato">Watch a 1 minute video&nbsp;&nbsp;<img loading = "lazy" src ="https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/Watch-video-ico.png" /></button></div></div> */}
            </div>
            <div class="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center rounded-lg px-6 py-10" style = {{background: "rgba(255, 255, 255, 0.4)", border: "1px solid #FFFFFF"}}>
                <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step closer to fostering a dynamic culture of appreciation</h2>
                <div class = "w-full flex justify-center items-center lato rewards">
                                <HubspotForm
                                portalId='6153052'
                                formId='50775a1c-3680-401b-b6ff-cc6e54043ed8'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                            />
                </div>
                <a href = "#" className = "text-gray-250 underline lato text-sm">Read our Privacy Policy</a>               
            </div>
        </div>
    </section>
    <section className = "flex w-full place-content-center py-5 lg:py-10 bg-white overflow-x-hidden">
        <div className = "w-full flex flex-col place-items-center">
            <h2 className = "bannerSectionTitle-2 text-gray-250 my-8 px-1"><span className = "relative">Features<img className = "absolute -bottom-1 right-0 xl:right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span> that meet your needs.</h2>
            <p class = "text-lg xl:text-xl my-1 text-gray-250 text-center">Offer your employees a discount program that benefits their financial health.</p>
            <div class="w-full rewardsSectionContainer flex flex-col justify-center items-center mt-5 md:mt-10 px-4 py-3 md::py-6 xl:py-10">
                <div className = "grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
                   <div className = "w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-center z-10">
                        <h2 className = "text-gray-250 rewardsSectionTitle my-3 w-full">Delightful Discounts</h2>
                        <p className = "text-gray-250 text-lg xl:text-xl my-3 leading-8">Enjoy exclusive discounts on products on a wide array of brands in categories like dining, travel, electronics, real estate and more.</p>
                    </div>
                    <div className = "w-full flex justify-center items-center z-10">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Group-13.png" />
                    </div>
                    <div className = "z-0 hidden lg:block absolute -left-24 lg:-top-28 xl:-top-20 2xl:top-0">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-1.png" />
                    </div>
                </div>
                <div className = "flex flex-col lg:flex-row-reverse justify-center items-start w-full my-12 relative">
                   <div className = "w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                        <h2 className = "text-gray-250 rewardsSectionTitle my-3 w-full">Redeemable Cashbacks</h2>
                        <p className = "text-gray-250 text-lg xl:text-xl my-3 leading-8">Earn extra cashback points over and above the discounts & easily redeem them from gift vouchers with zero-point expiration.</p>
                    </div>
                    <div className = "w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Group-14.png" />
                    </div>
                    <div className = "z-0 hidden lg:block absolute -right-24 bottom-0">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-2.png" />
                    </div>
                </div>
                <div className = "grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-ful my-12 relative">
                   <div className = "w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start z-10">
                        <h2 className = "text-gray-250 rewardsSectionTitle my-3 w-full">Analytics & Reporting</h2>
                        <p className = "text-gray-250 text-lg xl:text-xl my-3 leading-8">Effortlessly access and manage your employees' earnings & savings on online purchase with real-time customized reports.</p>
                    </div>
                    <div className = "w-full flex justify-center items-center z-10">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Group-15.png" />
                    </div>
                    <div className = "z-0 hidden lg:block absolute lg:-left-20 lg:-bottom-2 2xl:left-20 2xl:-bottom-10">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-3.png" />
                    </div>
                </div>
                <div className = "flex flex-col lg:flex-row-reverse justify-center items-start w-full my-12 relative">
                    <div className = "w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                        <h2 className = "text-gray-250 rewardsSectionTitle my-3 w-full">Gifts and greetings for every occasion</h2>
                        <p className = "text-gray-250 text-lg xl:text-xl my-3 leading-8">We take your burdern of gifting employees in any festive season. You can send customized gift cards and greetings cards to make their day special.</p>
                    </div>
                    <div className = "w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Group-16.png" />
                    </div>
                    <div className = "z-0 hidden lg:block absolute lg:-right-20 lg:-bottom-12 2xl:-right-28">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-4.png" />
                    </div>
                </div>
                <div className = "grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-ful my-12 relative">
                   <div className = "w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start z-10">
                        <h2 className = "text-gray-250 rewardsSectionTitle my-3 w-full">Make the service anniversary special</h2>
                        <p className = "text-gray-250 text-lg xl:text-xl my-3 leading-8">Make service anniversary memorable with service yearbook, awards and service milestone gifting options.<br/><br/>The service yearbook feature allows peers to collect and share memories in a personalized online yearbook format before an employee's work anniversary.</p>
                    </div>
                    <div className = "w-full flex justify-center items-center z-10">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Group-22.png" />
                    </div>
                    <div className = "z-0 hidden lg:block absolute lg:-left-20 lg:-bottom-16 2xl:-left-28">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-5.png" />
                    </div>
                </div>
            </div>
        </div>
        </section>
        <section className = "flex w-full place-content-center py-5 lg:py-12 bg-white">
        <div className = "flex flex-col justify-center items-center text-center">
            <h2 className = "newSectionTitle-center text-gray-250 text-center  w-full md:w-8/12 lg:w-1/2">Other features that make recognition more easy.</h2>
            <div className = "grid grid-cols-1 lg:grid-cols-3 gap-8 mt-10 mb-4 lg:my-12 2xl:my-20 w-11/12 lg:px-5 py-9 my-5 2xl:w-9/12 2xl:px-8">
                <div className = "flex flex-col justify-start shadow rounded-md bg-purple-500 p-8 text-left">
                    <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-28-1.png" height = "45" width = "45" />
                    <h2 className = "text-purple-100 font-bold text-lg xl:text-2xl mt-5">Merchandise</h2>
                    <p className = " my-3 text-purple-100">Give employees the flexibility to chose gift cards from popular in-store and online brands.</p>
                </div>
                <div className = "flex flex-col justify-start shadow rounded-md bg-purple-500 p-8 text-left">
                    <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-12.png" height = "45" width = "45" />
                    <h2 className = "text-purple-100 font-bold text-lg xl:text-2xl mt-5">Instant Notification</h2>
                    <p className = " my-3 text-purple-100">Once employees redeem points, gift card details are sent instantly to their emails.</p>
                </div>
                <div className = "flex flex-col justify-start shadow rounded-md bg-purple-500 p-8 text-left">
                    <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-9.png" height = "45" width = "45" />
                    <h2 className = "text-purple-100 font-bold text-lg xl:text-2xl mt-5">Easy Budgeting & Allocation</h2>
                    <p className = " my-3 text-purple-100">Get an overview of the amount allocated to, debited, credited and the balance remaining over a period of time.</p>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-3 justify-center items-center my-6">
                <button><a href="#retain" class="vc-new-orange-btn lato">Request Demo</a></button>
                <button><a href="#retain" class="vc-ghost-btn-orange shadow-md lato">Free Trial</a></button>
            </div>
        </div>
        </section>
        <section className = "bg-white py-10">
            <div className = "w-full lg:p-5 flex place-content-center">
                <div className = "newSectionContainer grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-9 justify-center items-center p-5 lg:p-10 xl:p-16 rounded-lg my-5 bg-purple-100">
                    <div className = "w-full flex flex-col text-center lg:text-left xl:ml-6 2xl:ml-0 justify-center lg:items-start items-center">
                        <h2 className = "text-orange text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl mt-7 mb-1 font-bold text-center">Integrations<span className = "text-gray-500"> to make</span></h2>
                        <h2 className = "text-gray-500 text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl mb-0 lg:mb-5 font-bold text-center">rewarding super quick.</h2>
                        <p className = "my-8 text-md lg:text-xl text-gray-500">Seemless integration with your existing HCM/HRIS platforms and single-sign-on (SSO) tools, making it easy for you to onboard.</p>
                        <div className = "grid grid-cols-1 gap-3 justify-center items-center lg:justify-start w-5/6 lg:w-1/2">
                            <button className = "vc-ghost-btn font-bold">Explore</button>
                        </div>
                    </div>
                    <div class="right my-10 lg:my-0">
                        <div class="grid grid-cols-3 gap-2 md:gap-4 text-white mb-2 md:mb-4">
                            <div class="bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/SAP.png" width="149" height="75" alt="SAP" />
                            </div>
                            <div class="bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802565/gatsbycms/uploads/2022/02/Yammer.png" width="149" height="37" alt="Yammer" />
                            </div>
                            <div class="bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/MS_Teams.png" width="149" height="34" alt="MS_Teams" />
                            </div>
                        </div>
                        <div class="grid grid-cols-5 gap-2 md:gap-4 text-white">
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802565/gatsbycms/uploads/2022/02/Workplace.png" width="109" height="29" alt="Workplace" />
                            </div>
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/Azure.png" width="93" height="28" alt="Azure" />
                            </div>
                            <div class="row-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/Slack.png" width="40" height="152" alt="Slack" />
                            </div>
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643954900/gatsbycms/uploads/2022/02/Oracle-integration.png" width="99" height="14" alt="Oracle" />
                            </div>
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/Okta.png" width="78" height="28" alt="Okta" />
                            </div>
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/People-strong.png" width="129" height="23" alt="PeopleStrong" />
                            </div>
                            <div class="col-span-3 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/Office365.png" width="133" height="30" alt="Office 365" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className= "testimonial-slider container text-center px-6 py-10 md:py-28">
            <TestimonialsEn />
        </div>
    </Layout>
  )
}

export default Newpulsepage